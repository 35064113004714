<template>
    <el-dialog title="底度管理" :visible="bdRecordVisible" @close="closeBdPage" top="0"
               custom-class="bd-dialog" center :close-on-click-modal="false" append-to-body>
        <el-form :inline="true" :model="bdForm" >
            <el-form-item label="分类" prop="classifyEn">
                <el-select v-model="bdForm.classifyEn" placeholder="请选择" clearable @change='classifyChangeSearch'>
                    <el-option v-for="(item, index) in classifyOption" :key="index" :label="item" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="字段" prop="columnEn">
                <el-select v-model="bdForm.columnEn" placeholder="请选择" clearable>
                    <el-option v-for="(item, index) in columnOption" :key="index" :label="item.columnZhAndEn" :value="item.columnEn">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模糊匹配" prop="columnZhLike">
                <el-input v-model="bdForm.columnZhLike" title="不可与分类条件混用" placeholder="请输入字段名称" clearable @change='likeSearch'></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="queryBdRecordData">查询</el-button>
                <el-button type="danger" @click="openAddBdRecordDialog">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="bdRecordData" border size="small" stripe style="width: 100%"
                  :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column prop="classifyZh" label="分类"/>
            <el-table-column prop="columnZh" label="字段（中文）" />
            <el-table-column prop="columnEn" label="字段含义（英文）" />
            <el-table-column prop="createTime" label="创建时间" :formatter="dataFormat"/>
            <el-table-column prop="updateTime" label="更新时间" :formatter="dataFormat"/>
            <el-table-column prop="value" label="底度值">
                <template slot-scope="scope">
                    <el-input-number style="width:140px" :precision="2" :controls="false"  v-model="scope.row.value"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="updateRecord(scope.$index, scope.row)" type="warning">保存</el-button>
                    <el-button size="mini" @click="deleteRecord(scope.$index, scope.row)" type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin-top: 15px;text-align: right;">
            <el-pagination background layout="total, prev, pager, next, jumper" :total="total"
                           @current-change="handleCurrentChange" :current-page.sync="curr"/>
        </div>

        <el-dialog title="新增底度" custom-class="addBd-dialog" :visible.sync="addBdRecordVisible" @close='closeAddBdRecordDialog' append-to-body>
            <el-form :model="addBdForm" ref="addBdForm" :label-position="labelPosition" label-width="80px" size="mini" :rules="addRules">
                <el-form-item label="分类" prop="classifyEn">
                    <el-select style="width:300px" v-model="addBdForm.classifyEn" placeholder="请选择" clearable @change='classifyChangeSearch2'>
                        <el-option v-for="(item, index) in classifyOption2" :key="index" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字段" prop="baseDegreeDefineId">
                    <el-select style="width:300px" v-model="addBdForm.baseDegreeDefineId" placeholder="请选择">
                        <el-option v-for="(item, index) in columnOption2" :key="index" :label="item.columnZhAndEn" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="底度值" prop="value">
                    <el-input-number :precision="2" :controls="false" style="width:300px" v-model.number="addBdForm.value" placeholder="请输入底度值" clearable></el-input-number>
                </el-form-item>
                <el-form-item size="large" style="margin-top: 50px">
                    <el-button type="danger" @click="addBdRecordData">添加</el-button>
                    <el-button type="primary" @click="closeAddBdRecordDialog">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-dialog>
</template>
<script>
    import {queryBaseDegreeDefineClassify, queryBaseDegreeRecord, addBaseDegreeRecord,
        updateBaseDegreeRecord, deleteBaseDegreeRecord} from "../../../../api/station/deviceInfoApi";
    export default {
        name: "DeviceBaseDegreeRecord",
        props: {

            bdRecordVisible: {

                type: Boolean,
                default: false
            },
        },
        data() {

            return {
                labelPosition:'left',
                addBdRecordVisible:false,
                bdForm: {

                    classifyEn: '',
                    columnEn: '',
                    columnZhLike: ''
                },
                addBdForm: {

                    classifyEn: '',
                    baseDegreeDefineId: '',
                    value:'',
                },
                addRules: {
                    classifyEn: [
                        { required: true, message: '分类不能为空', trigger: 'change'}
                    ],
                    baseDegreeDefineId: [
                        { required: true, message: '字段不能为空', trigger: 'change'}
                    ],
                    value: [
                        { required: true, message: '底度不能为空', trigger: 'blur'}
                    ]
                },
                classifyData:{},
                classifyOption:{},
                columnOption: [],
                classifyOption2:{},
                columnOption2: [],
                currWholeId: '',
                bdRecordData: [],
                listLoading: false,
                total: 0,
                curr: 1,
                size: 10
            }
        },
        methods: {
            initRecordPage(id) {

                this.currWholeId = id;
                queryBaseDegreeDefineClassify().then((res) => {

                    this.classifyData = res;
                    this.classifyOption = [];
                    this.classifyOption2 = [];
                    for(let key in res) {

                        this.classifyOption.push(key)
                        this.classifyOption2.push(key)
                        let tmp = res[key];
                        for (let i = 0; i < tmp.length; i ++) {

                            tmp[i].columnZhAndEn = tmp[i].columnZh + "-" + tmp[i].columnEn;
                        }
                    }
                }).catch(err=> console.log(err));

                this.queryBdRecordData();
            },
            classifyChangeSearch() {

                this.bdForm.columnEn = null;
                this.bdForm.columnZhLike = null;
                this.columnOption = [];
                this.columnOption = this.classifyData[this.bdForm.classifyEn];
            },
            likeSearch () {

                this.bdForm.classifyEn = null;
                this.bdForm.columnEn = null;
                this.bdForm.columnOption = [];
            },
            classifyChangeSearch2() {

                this.addBdForm.baseDegreeDefineId = null;
                this.columnOption2 = [];
                this.columnOption2 = this.classifyData[this.addBdForm.classifyEn];
            },
            openAddBdRecordDialog() {

                this.addBdRecordVisible = true;
            },
            closeAddBdRecordDialog(){

                this.addBdRecordVisible = false;
            },
            addBdRecordData() {

                this.$refs.addBdForm.validate(valid => {
                    if (valid) {

                        this.$confirm('此操作将影响上报数据, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                            let param = {

                                deviceWholeId: this.currWholeId,
                                baseDegreeDefineId : this.addBdForm.baseDegreeDefineId,
                                value: this.addBdForm.value
                            }
                            addBaseDegreeRecord(param).then((res) => {

                                if (res.code === '200') {

                                    if (res.data === -1) {

                                        this.$message({
                                            showClose: true,
                                            message: '已经存在该列，不能重复添加！',
                                            type: 'error'
                                        });
                                    } else {

                                        this.$message({
                                            showClose: true,
                                            message: '添加成功！',
                                            type: 'success'
                                        });
                                        this.addBdForm.classifyEn = null;
                                        this.addBdForm.baseDegreeDefineId = null;
                                        this.addBdForm.value = null;
                                        this.closeAddBdRecordDialog();
                                        this.queryBdRecordData();
                                    }
                                } else {

                                    this.$message({
                                        showClose: true,
                                        message: '添加失败！',
                                        type: 'error'
                                    });
                                }
                            }).catch(err=> console.log(err));
                        }).catch(() => {

                        });
                    }
                });
            },
            updateRecord(index, row) {

                // 这里需要二级校验是否保存
                this.$confirm('此操作将修改该参数的值, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    let param = {

                        id: row.id,
                        value:row.value
                    }
                    updateBaseDegreeRecord(param).then((res) => {

                        if (res.code === '100') {

                            this.$message({
                                showClose: true,
                                message: '修改成功！',
                                type: 'success'
                            });
                            this.queryBdRecordData();
                        } else {

                            this.$message({
                                showClose: true,
                                message: '修改失败！',
                                type: 'error'
                            });
                        }
                    }).catch(err=> console.log(err));
                }).catch(() => {

                });
            },
            deleteRecord(index, row) {

                // 这里需要二级校验是否保存
                this.$confirm('此操作将删除该参数, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    let param = {

                        id: row.id
                    }
                    deleteBaseDegreeRecord(param).then((res) => {

                        if (res.code === '100') {

                            this.$message({
                                showClose: true,
                                message: '删除成功！',
                                type: 'success'
                            });
                            this.queryBdRecordData();
                        } else {

                            this.$message({
                                showClose: true,
                                message: '删除失败！',
                                type: 'error'
                            });
                        }
                    }).catch(err=> console.log(err));
                }).catch(() => {
                });
            },
            queryBdRecordData() {

                this.listLoading = true;
                let param = {

                    deviceWholeId: this.currWholeId,
                    classifyEn: this.bdForm.classifyEn == null ? '' : this.bdForm.classifyEn.split('-')[1],
                    columnEn: this.bdForm.columnEn,
                    columnZhLike: this.bdForm.columnZhLike,
                    curr: this.curr,
                    size: this.size,
                }
                queryBaseDegreeRecord(param).then((res) => {

                    this.listLoading = false;
                    this.bdRecordData = res.listResult;
                    this.total = res.totalNum;
                }).catch(err=> console.log(err));
            },
            handleCurrentChange(val) {

                this.curr = val;
                this.queryEventRecordData();
            },
            closeBdPage() {

                this.$parent.closeBdRecordPage();
            },
            dataFormat (row, column) {

                let data = row[column.property]
                if (data ===null) {

                    return '--'
                }
                let dt = new Date(data)
                return dt.getFullYear() + '-'
                    + this.addZero((dt.getMonth() + 1))
                    + '-' + this.addZero(dt.getDate())
                    + ' ' + this.addZero(dt.getHours())
                    + ':' + this.addZero(dt.getMinutes())
                    + ':' + this.addZero(dt.getSeconds())
            },
            addZero(num) {

                if (num.toString().length === 1) {

                    return "0" + num;
                }
                return num;
            }
        }
    }
</script>
<style>
    .bd-dialog {

         width: 1200px;
    }
    .addBd-dialog {

        width: 500px;
        height: 350px;
    }
</style>
